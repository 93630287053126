import Modal from "react-modal"
import {
    ButtonClass, FixButtonClass, GrayButtonClass, InputClass,
    modalBasicIndent, modalStyles, RedButtonClass
} from "contexts/style"
import React, { MouseEvent, useRef, useEffect, useState } from "react"
import { FcApproval } from "react-icons/fc"
import { FormPatients } from "./FormPatients"
import { getDateString } from "contexts/dateUtils"
import { Loading } from "./Loading"
import showMessage from "./showMessage"
import { getSequenceNo } from "graphql/useSequenceFindOneAndUpdate"
import { useMongoDB } from "contexts/MongoDBContext"
import { BOOKED_STATUS, OUTSIDE_TYPE, PRE_BOOK_STATUS, DOCK_TYPE, CMC_TYPE } from "contexts/enviroments"
import options from "contexts/options.json"
import { useRealmApp } from "contexts/RealmApp"
import { useInternalDoctors } from "graphql/useDoctors"
import { useUpdateBooking, useUpdateRelatedBooking } from "contexts/useCollection"
import { getTmpPatientId } from "contexts/functions"
import FileChooser, { FileError, FileHandler } from "./FormFile"

interface InterviewTypeList {
    [key: string]: number
}

export const ExamEntryForm = ({ isExamModalOpen, isUpdated, setIsUpdated, setIsExamModalOpen, selectBookingId, selectInfo, baseResources, event, refetch }:
    { isExamModalOpen: boolean, isUpdated: boolean, setIsUpdated: any, setIsExamModalOpen: any, selectBookingId: string, selectInfo: any, baseResources: KV[], event: any, refetch?: () => Promise<void> }) => {

    // Set State Param
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [patientId, setPatientId] = useState<string>("")
    const [patientName, setPatientName] = useState<string>("")
    const [patientNameKana, setPatientKana] = useState<string>("")
    const [birthDate, setBirthDate] = useState<string>("")
    const [gender, setGender] = useState<string>("M")
    const [tel, setTel] = useState<string>("")
    const [visitID, setVisitID] = useState<string>("") // TODO: visitIDをどこから取得する
    const [bookDate, setBookDate] = useState<Date>(new Date())
    const [bookStartHour, setBookStartHour] = useState<string>('09')
    const [bookStartMin, setBookStartMin] = useState<string>('00')
    const [bookEndHour, setBookEndHour] = useState<string>('09')
    const [bookEndMin, setBookEndMin] = useState<string>('00')
    const [exam, setExam] = useState<string>("")
    const [doctorId, setDoctorId] = useState<string>("")
    const [doctorName, setDoctorName] = useState<string>("")
    const [interviewType, setInterviewType] = useState<string>("")
    const [bookingType, setBookingType] = useState<number>(99)
    const [isTemp, setIsTemp] = useState<boolean>(false)
    const [bookId, setBookId] = useState<string>("")
    const [bookStatus, setBookStatus] = useState<number>(0)
    const [comment, setComment] = useState<string>("")
    const [appointmentMethod, setAppointmentMethod] = useState<string>("来院")
    const [interviewTypeField, setInterviewTypeField] = useState<InterviewTypeList>({})
    const [files, setFiles] = useState<FileItem[]>([])

    const updateBookings = useUpdateRelatedBooking()

    const patientRef = useRef<Patient>({});
    const fileChooserRef = useRef({} as FileHandler)

    const realmAppContext = useRealmApp()
    const { db } = useMongoDB()

    const { doctors, loading } = useInternalDoctors()

    const app = useRealmApp()

    const getTypeById = async (id: string) => {
        const result = app.resources.find(resource => resource.id === id);
        return result ? result.type : null;
    }

    const resetStates = () => {
        setPatientName("")
        setPatientKana("")
        setPatientId("")
        setIsExamModalOpen(false)
        setIsUpdated(false)
        setBookStatus(0)
        setIsLoading(false)
        setInterviewType("")
        setComment("")
        setFiles([])
    }

    // 患者情報の更新
    useEffect(() => {
        const patient = {
            birthDate: new Date(birthDate),
            gender: gender,
            isTemp: isTemp,
            name: patientName,
            nameKana: patientNameKana,
            patientId: patientId,
            tel: tel,
            updated: new Date()
        }
        patientRef.current = patient;
    }, [patientId, patientName, patientNameKana, birthDate, gender, tel, isTemp])

    useEffect(() => {
        if (refetch && !isExamModalOpen) refetch()
    }, [isExamModalOpen])

    // カレンダークリック後に発動
    useEffect(() => {
        if (selectInfo?.resource?.id) {
            if (selectInfo.start instanceof Date) {
                setBookDate(selectInfo.start);
                setBookStartHour(`${selectInfo.start.getHours()}`.padStart(2, '0'))
                setBookStartMin(`${selectInfo.start.getMinutes()}`.padStart(2, '0'))
            }
            if (selectInfo.end instanceof Date) {
                setBookEndHour(`${selectInfo.end.getHours()}`.padStart(2, '0'))
                setBookEndMin(`${selectInfo.end.getMinutes()}`.padStart(2, '0'))
            }
            setExam(selectInfo.resource.id)
        }
    }, [selectInfo])

    useEffect(() => {
        // 面談種別を取得
        const fetchTypeList = async () => {
            const interviewTypeList = await getTypeById(exam)
            if (interviewTypeList && Object.keys(interviewTypeList).length > 0) {
                setInterviewTypeField(interviewTypeList)
                //if (interviewType === "") {
                //    const firstInterviewType = Object.keys(interviewTypeList)[0]
                //    setInterviewType(firstInterviewType)
                //}
            }
        }
        fetchTypeList()
    }, [exam])

    useEffect(() => {
        // 面談時間を取得
        let interviewTime = interviewTypeField[interviewType]

        if (interviewTime === undefined && !isUpdated) {
            const keys = Object.keys(interviewTypeField)
            if (keys.length > 0) {
                const firstKey = keys[0]
                interviewTime = interviewTypeField[firstKey]
            }
        }

        // 終了時刻をinterviewTimeだけ進ませる
        const startHourInt = parseInt(bookStartHour, 10);
        const startMinInt = parseInt(bookStartMin, 10);
        const totalStartMinutes = startHourInt * 60 + startMinInt;
        const totalEndMinutes = totalStartMinutes + interviewTime;
        const endHour = Math.floor(totalEndMinutes / 60).toString().padStart(2, '0');
        const endMin = (totalEndMinutes % 60).toString().padStart(2, '0');
        if (!isNaN(parseInt(endHour, 10))) {
            setBookEndHour(endHour);
            setBookEndMin(endMin);
        }
    }, [interviewType, interviewTypeField])


    // 更新イベントの時
    useEffect(() => {
        if (isUpdated) {
            // event情報をモーダルにセット
            setPatientId(event?.extendedProps.patientID)
            setPatientName(event?.extendedProps.patientName)
            setExam(event?.extendedProps.modalityID)
            const eventStart = event?.start
            const eventEnd = event?.end
            setBookDate(eventStart)
            setBookStartHour(String(eventStart.getHours()).padStart(2, '0'))
            setBookStartMin(String(eventStart.getMinutes()).padStart(2, '0'))
            setBookEndHour(String(eventEnd.getHours()).padStart(2, '0'))
            setBookEndMin(String(eventEnd.getMinutes()).padStart(2, '0'))
            setDoctorId(event?.extendedProps.doctorID)
            setDoctorName(event?.extendedProps.doctor)
            setBookId(event?.extendedProps.id)
            setBookStatus(event?.extendedProps.bookingStatus)
            setAppointmentMethod(event?.extendedProps.appointmentMethod)
            setVisitID(event?.extendedProps.visitID)
            setComment(event?.extendedProps.comment)
            setFiles(event?.extendedProps.files)
            setBookingType(event?.extendedProps.bookingType)
            setInterviewType(event?.extendedProps.interviewType)
        }

    }, [event, isUpdated])

    const handleClose = async (e: MouseEvent<HTMLButtonElement>) => {
        resetStates()
    }

    const updateBookDate = async (e: any) => {
        setBookDate(new Date(e.target.value))
    }

    const updateBookStartHour = async (e: any) => {
        setBookStartHour(`${e.target.value}`)
    }
    const updateBookStartMin = async (e: any) => {
        setBookStartMin(`${e.target.value}`)
    }

    const updateBookEndHour = async (e: any) => {
        setBookEndHour(`${e.target.value}`)
    }
    const updateBookEndMin = async (e: any) => {
        setBookEndMin(`${e.target.value}`)
    }

    const updateExam = async (e: any) => {
        setExam(`${e.target.value}`)
    }

    const updateBookingType = async (e: any) => {
        setBookingType(parseInt(e.target.value))
    }

    const updateAppointmentMethod = async (e: any) => {
        setAppointmentMethod(e.target.value)
    }

    const updateDoctor = async (e: any) => {
        if (e.target.value !== 'none') {
            const selectedOption = e.target.options[e.target.selectedIndex]
            const doctorName = selectedOption.getAttribute("data-doctor")
            setDoctorId(`${e.target.value}`)
            setDoctorName(doctorName)
        } else {
            setDoctorId('')
            setDoctorName('')
        }
    }

    const updateInterviewType = async (e: any) => {
        setInterviewType(`${e.target.value}`)
    }

    const handlePreFix = async () => {
        if (!isUpdated && patientName === '') {
            alert('患者名を入力してください')
            return
        }
        if (!isUpdated && patientNameKana === '') {
            alert('患者名カナを入力してください')
            return
        }
        if (bookingType === 99) {
            alert('種別を選択してください')
            return
        }

        // start, end
        const start = new Date(
            bookDate.getFullYear(),
            bookDate.getMonth(),
            bookDate.getDate(),
            parseInt(bookStartHour),
            parseInt(bookStartMin)
        )
        const end = new Date(
            bookDate.getFullYear(),
            bookDate.getMonth(),
            bookDate.getDate(),
            parseInt(bookEndHour),
            parseInt(bookEndMin)
        )

        // EndがStartより前だったら、エラー終了する
        if (end < start) {
            alert('終了時刻が開始時刻より前になっています。')
            setIsLoading(false)
            return
        }

        if (!await showMessage(`問診を仮予約しますか？`, { confirm: true })) {
            return
        }
        setIsLoading(true)


        // 予約が重複していないかチェック
        const existsBooking = await db?.collection('bookings').find({
          modalityID: exam,
          start: { $lt: end },
          end: { $gt: start },
          _id: { $ne: bookId }
        });
       if (existsBooking && existsBooking.length > 0) {
            // 自身の予約IDのみの場合除外。
            const isExist = existsBooking.find((item: any) => item._id !== bookId)
            if (isExist) {
                alert('他の予約が重複しています。')
                setIsLoading(false)
                return
            }
        }

        // 予約番号(アクセッション番号)発行
        const seqNo = await getSequenceNo(db)
        if (!seqNo) {
            alert('予約番号の発番に失敗しました。管理者へお問合せください。')
            return false
        }

        // 受付時間は5分前
        const fiveMinutesAgo = new Date(start.getTime() - 5 * 60000)
        const checkin = fiveMinutesAgo.toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' });

        // 予約情報をセット
        const booking: Booking = {
            modalityID: exam,
            clinicID: String(options.centralClinic.id),
            clinicName: String(options.centralClinic.name),
            doctor: doctorName,
            bookingType,
            bookingMethod: 1, // WEB
            patient: patientId,
            patientName,
            userID: realmAppContext.currentUser?.id,
            doctorID: doctorId,
            bookingStatus: PRE_BOOK_STATUS,
            appointmentMethod: appointmentMethod,
            start,
            end,
            interviewType,
            comment,
            files,
            updated: new Date(),
            created: new Date(),
        }

        // 患者IDがなかったら、仮患者を作成
        if (patientId === '') {
            const tmpPatient: Patient = {
                isTemp: true,
                name: patientName,
                nameKana: patientNameKana,
                birthDate: new Date(birthDate),
                gender,
                tel,
            }
            const tmpPatientID = getTmpPatientId(1000000000, 9000000000).toString()
            booking.patientID = tmpPatientID
            tmpPatient.patientId = tmpPatientID
            tmpPatient._id = String(tmpPatientID)
            const _result_patient = await db?.collection('patients').insertOne(tmpPatient);
        } else {
            booking.patientID = patientId
        }


        if (isUpdated) {
            // ドックはcheckinを変更しない
            if (interviewType !== 'ドック') {
                booking.checkin = checkin
            }

            // Set files
            const updatedFiles = await fileChooserRef.current.saveFiles(bookId)
            booking.files = updatedFiles

            // visitsも更新
            const existsBooking = await db?.collection('bookings').findOne({ _id: bookId });
            if (db && existsBooking.visitID) {
                await db.collection('visits').updateOne({ _id: existsBooking.visitID }, { $set: { comment: booking.comment, files: booking.files, updated: new Date() } })
            }
            const _result = await db?.collection('bookings').updateOne(
              { _id: bookId },
              { $set: booking }
            );
            await showMessage(`診療予約を更新しました。\r\n予約番号 : ${seqNo}`, { keep: true })
        } else {
            booking.checkin = checkin
            booking._id = String(seqNo)
            booking.visitID = String(seqNo)
            // Set files
            const updatedFiles = await fileChooserRef.current.saveFiles(String(seqNo))
            booking.files = updatedFiles
            const _result_bookings = await db?.collection('bookings').insertOne(booking);

            // 新規の場合、visitIDも登録
            const visit: Visit = {
                _id: String(seqNo),
                bookingIDs: [String(seqNo)],
                bookingType: booking.bookingType,
                clinicID: booking.clinicID,
                clinicName: booking.clinicName,
                comment: booking.comment,
                files: booking.files,
                department: booking.department,
                diagnosis: booking.diagnosis,
                doctor: booking.doctor,
                start: fiveMinutesAgo,
                end: booking.end,
                patient: String(booking.patientID),
                patientName: booking.patientName
            }
            const _result_visits = await db?.collection('visits').insertOne(visit);

            await showMessage(`診療予約が完了しました。\r\n予約番号 : ${seqNo}`, { keep: true })
        }

        setIsLoading(false)
        resetStates()
    }

    const handleFix = async () => {
        const keyword = bookStatus === 0 ? "確定" : "更新";
        if (patientId === '') {
            alert('患者IDを入力してください')
            return
        }
        if (!isUpdated && patientName === '') {
            alert('患者名を入力してください')
            return
        }
        if (!isUpdated && patientNameKana === '') {
            alert('患者名カナを入力してください')
            return
        }
        if (bookingType === 99) {
            alert('種別を選択してください')
            return
        }

        // start, end
        const start = new Date(
            bookDate.getFullYear(),
            bookDate.getMonth(),
            bookDate.getDate(),
            parseInt(bookStartHour),
            parseInt(bookStartMin)
        )
        const end = new Date(
            bookDate.getFullYear(),
            bookDate.getMonth(),
            bookDate.getDate(),
            parseInt(bookEndHour),
            parseInt(bookEndMin)
        )

        // EndがStartより前だったら、エラー終了する
        if (end <= start) {
            alert('終了時刻が開始時刻より前になっています。')
            setIsLoading(false)
            return
        }

        if (!await showMessage(`問診を${keyword}しますか？`, { confirm: true })) {
            return
        }
        setIsLoading(true)


        // 予約が重複していないかチェック
        const existsBooking = await db?.collection('bookings').find({
          modalityID: exam,
          start: { $lt: end },
          end: { $gt: start },
          _id: { $ne: bookId }
        });
        if (existsBooking && existsBooking.length > 0) {
            alert('他の予約が重複しています。')
            setIsLoading(false)
            return
        }

        // 受付時間は5分前
        const fiveMinutesAgo = new Date(start.getTime() - 5 * 60000)
        const checkin = fiveMinutesAgo.toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' });

        // 予約情報をセット
        const booking: Booking = {
            modalityID: exam,
            clinicID: String(options.centralClinic.id),
            clinicName: String(options.centralClinic.name),
            doctor: doctorName,
            bookingType,
            bookingMethod: 1, // WEB
            patientID: patientId,
            patient: patientId,
            patientName,
            userID: realmAppContext.currentUser?.id,
            appointmentMethod: appointmentMethod,
            doctorID: doctorId,
            bookingStatus: BOOKED_STATUS,
            interviewType: interviewType,
            start,
            end,
            comment,
            files,
            updated: new Date(),
            created: new Date(),
        }

        
        if (isUpdated) {
            // Set files
            const updatedFiles = await fileChooserRef.current.saveFiles(bookId)
            booking.files = updatedFiles
            // ドック、CMCはcheckinを変更しない
            if (interviewType !== 'ドック' && bookingType !== DOCK_TYPE && bookingType !== CMC_TYPE) {
                booking.checkin = checkin
            }
            // visitsも更新
            const existsBooking = await db?.collection('bookings').findOne({ _id: bookId });
            if (db && existsBooking.visitID) {
                await db?.collection('visits').updateOne({ _id: existsBooking.visitID }, { $set: { comment: booking.comment, files: booking.files, updated: new Date() } })
            }

            const _result = await db?.collection('bookings').updateOne(
              { _id: bookId },
              { $set: booking }
            );
            await showMessage(`診療予約を更新しました。\r\n予約番号 : ${bookId}`, { keep: true })
        } else {
            // 新規は受付時間をセット
            booking.checkin = checkin

            // 予約番号(アクセッション番号)発行
            const seqNo = await getSequenceNo(db)
            if (!seqNo) {
                alert('予約番号の発番に失敗しました。管理者へお問合せください。')
                return false
            }

            booking._id = String(seqNo)
            booking.visitID = String(seqNo);
            // Set files
            const updatedFiles = await fileChooserRef.current.saveFiles(String(seqNo))
            booking.files = updatedFiles
            const _result_bookings = await db?.collection('bookings').insertOne(booking);
            await showMessage(`診療予約が完了しました。\r\n予約番号 : ${seqNo}`, { keep: true })

            // 新規の場合、visitIDも登録
            const visit: Visit = {
                _id: String(seqNo),
                bookingIDs: [String(seqNo)],
                bookingType: booking.bookingType,
                clinicID: booking.clinicID,
                clinicName: booking.clinicName,
                comment: booking.comment,
                files: booking.files,
                department: booking.department,
                diagnosis: booking.diagnosis,
                doctor: booking.doctor,
                start: fiveMinutesAgo,
                end: booking.end,
                patient: String(booking.patientID),
                patientName: booking.patientName
            }
            const _result_visits = await db?.collection('visits').insertOne(visit);
        }
        console.log(booking)

        // コメントの共通化: 同じ日程で別モダリティの予約がある場合、コメントを統一する
        if (comment && booking.start && booking.patientID) {
            await updateBookings(new Date(booking.start), booking.patientID, { comment })
        }

        setIsLoading(false)
        resetStates()

    }

    const handleDelete = async () => {
        // 更新フラグが立っていたら、そのBookIDを取消
        if (isUpdated) {
            if (!await showMessage(`問診を取り消しますか？`, { confirm: true })) {
                return
            }
            setIsLoading(true)
            // visitsを検索
            const existsBooking = await db?.collection('bookings').findOne({ _id: bookId });
            if (db && existsBooking.visitID) {
                const visit = await db.collection('visits').findOne({ _id: existsBooking.visitID })
                // visit.bookingIDsが１つの場合、visitも削除
                if (visit.bookingIDs.length === 1) {
                    // visit.bookingIDs[0]がbookIdと一致していた場合、visitも削除
                    if (visit.bookingIDs[0] === bookId) {
                        await db.collection('visits').deleteOne({ _id: existsBooking.visitID })
                    } else {
                        return await showMessage(`予約を取り消しできませんでした。\r\n`, { keep: true })
                    }
                } else {
                    // visit.bookingIDsから_idを除外
                    const bookingIDs = visit.bookingIDs.filter((id: string) => id !== bookId)
                    await db.collection('visits').updateOne({ _id: existsBooking.visitID }, { $set: { bookingIDs } })
                }
                // booking._idを削除
                await db.collection('bookings').deleteOne({ _id: bookId })
            }
            await showMessage(`予約を取り消しました。\r\n`, { keep: true })
        }
        setIsLoading(false)
        resetStates()
    }

    const handleAfterFileSave = (files: FileItem[], error: FileError[]) => {
        console.log(files)
        setFiles(files)
    }

    const updateComment = async (e: any) => {
        setComment(e.target.value)
    }

    return <>
        {isLoading && <Loading full />}
        <Modal
            isOpen={isExamModalOpen}
            ariaHideApp={false}
            style={modalStyles}
            contentLabel="External Opening Modal"
            shouldCloseOnOverlayClick={false}
        >
            <div className="flex pt-4">
                <span className="text-white">問診予約番号：{isUpdated ? event?.id : "新規"}</span>
            </div>
            <div className={modalBasicIndent}><FcApproval />
                患者情報
            </div>
            <FormPatients
                bookingId={isUpdated ? event?.id : ""}
                patientId={patientId}
                visitId={visitID}
                setPatientId={setPatientId}
                patientName={patientName}
                setPatientName={setPatientName}
                patientNameKana={patientNameKana}
                setPatientKana={setPatientKana}
                setBirthDate={setBirthDate}
                gender={gender}
                setGender={setGender}
                tel={tel}
                setTel={setTel}
                isUpdated={isUpdated}
                setIsTemp={setIsTemp}
            />

            <div className={modalBasicIndent}><FcApproval />診療情報</div>
            <div className="p-3">
                <div className="grid grid-cols-4">
                    <div>
                        <span className="text-white w-36">問診情報</span>
                        <span className="w-36">
                            <select className={InputClass} value={exam} onChange={(e) => updateExam(e)}>
                                {baseResources.map(item => (
                                    <option key={item.id} value={item.id}>
                                        {item.title}
                                    </option>
                                ))}
                            </select>
                        </span>
                    </div>
                    <div>
                        <span className="text-white w-36">担当医</span>
                        <span className="w-36">
                            <select className={InputClass} value={doctorId} onChange={(e) => updateDoctor(e)}>
                                <option value={'none'}>
                                    選択して下さい
                                </option>
                                {doctors.map(item => (
                                    <option key={item._id} value={item._id} data-doctor={item.name}>
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                        </span>
                    </div>
                    <div>
                        <span className="text-white w-36">診療種別</span>
                        <span className="w-36">
                            <select className={InputClass} value={interviewType} onChange={(e) => updateInterviewType(e)}>
                                <option value="-">-</option>
                                {Object.keys(interviewTypeField).map(item => (
                                    <option key={item} value={item}>
                                        {item}
                                    </option>
                                ))}
                            </select>
                        </span>

                    </div>
                    <div className="col-span-1">
                        <span className="text-white w-36">種別</span>
                        <span className="w-36">
                            <select className={InputClass} value={bookingType} onChange={(e) => updateBookingType(e)}>
                                <option > - </option>
                                <option key="0" value="0">外来</option>
                                <option key="1" value="1">外注</option>
                                <option key="2" value="2">ドック</option>
                                <option key="6" value="6">CMC</option>
                            </select>
                        </span>
                    </div>
                    <div className="col-span-1 mt-2">
                        <span className="text-white w-36">診療方法</span>
                        <span className="w-36">
                            <select className={InputClass} value={appointmentMethod} onChange={(e) => updateAppointmentMethod(e)}>
                                <option > - </option>
                                <option key="0" value="来院">来院</option>
                                <option key="1" value="電話">電話</option>
                                <option key="2" value="Zoom">Zoom</option>
                            </select>
                        </span>
                    </div>
                </div>
                <div className={modalBasicIndent}></div>
                <FileChooser title="添付ファイル" ref={fileChooserRef} bookingID={bookId} files={files} callback={handleAfterFileSave} />
            </div>

            <div className={modalBasicIndent}><FcApproval />予約情報</div>
            <div className="p-3">
                <div className="grid grid-cols-4">
                    <div className="col-span-1">
                        <span className="text-white w-36 block">予約日</span>
                        <span className="w-36">
                            <input className={InputClass} min={getDateString()} type="date" name="date"
                                value={getDateString(bookDate)} onChange={(e) => updateBookDate(e)} />
                        </span>
                    </div>
                    <div className="col-span-1">
                        <div className="col-span-1">
                            <span className="text-white font-bold block">開始時刻</span>
                            <span className="flex">
                                <select className={InputClass} value={bookStartHour} onChange={(e) => updateBookStartHour(e)}>
                                    {Array.from({ length: 12 }, (_, index) => {
                                        const hour = index + 6
                                        const formattedHour = hour < 10 ? `0${hour}` : `${hour}`
                                        return <option key={formattedHour} value={formattedHour}>{formattedHour}</option>
                                    })}
                                </select>
                                <select className={InputClass} value={bookStartMin} onChange={(e) => updateBookStartMin(e)}>
                                    {Array.from({ length: 12 }, (_, index) => {
                                        const minute = index * 5
                                        const formattedMinute = minute < 10 ? `0${minute}` : `${minute}`
                                        return <option key={formattedMinute} value={formattedMinute}>{formattedMinute}</option>
                                    })}
                                </select>
                            </span>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <div className="col-span-1">
                            <span className="text-white font-bold block">終了時刻</span>
                            <span className="flex">
                                <select className={InputClass} value={bookEndHour} onChange={(e) => updateBookEndHour(e)}>
                                    {Array.from({ length: 12 }, (_, index) => {
                                        const hour = index + 6
                                        const formattedHour = hour < 10 ? `0${hour}` : `${hour}`
                                        return <option key={formattedHour} value={formattedHour}>{formattedHour}</option>
                                    })}
                                </select>
                                <select className={InputClass} value={bookEndMin} onChange={(e) => updateBookEndMin(e)}>
                                    {Array.from({ length: 12 }, (_, index) => {
                                        const minute = index * 5
                                        const formattedMinute = minute < 10 ? `0${minute}` : `${minute}`
                                        return <option key={formattedMinute} value={formattedMinute}>{formattedMinute}</option>
                                    })}
                                </select>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="p-3">
                <div className="grid grid-cols-3">
                    <span className="text-white col-span-2 flex">
                        コメント・メモ欄
                        <textarea className="ml-2 rounded-md text-black focus:text-black" style={{ width: '80%' }} value={comment} rows={4} onChange={(e) => updateComment(e)}></textarea>
                    </span>
                </div>
            </div>

            <div className="flex my-4 justify-center">
                <button name="close" className={GrayButtonClass} onClick={handleClose}>閉じる</button>
                {isUpdated ? <button name="close" className={RedButtonClass} onClick={handleDelete}>取消</button> : <></>}
                {bookStatus === 0 ? <button name="book" className={ButtonClass} onClick={handlePreFix}>仮予約</button> : <></>}
                <button name="book" className={FixButtonClass} onClick={handleFix}>{bookStatus === 0 ? <>確定</> : <>更新</>}</button>
            </div>

        </Modal>
    </>

}
