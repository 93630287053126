import React, { MouseEvent } from 'react'
import { gql, useQuery, useReactiveVar } from '@apollo/client'
import { Link, useNavigate } from 'react-router-dom';
import PlusIcon from '@heroicons/react/24/solid/PlusIcon';
import { Loading } from 'components/Loading';
import { FilterCheck, FilterInput } from 'components/UIParts';
import { filterStates } from 'graphql/RealmApolloProvider';
import options from 'contexts/options.json';

const heads = [
    { field: "internal", text: "院内" },
    { field: "notice", text: "通知メール" },
    { field: "clinic.clinic_name", text: "施設名" },
    { field: "name", text: "氏名" },
    { field: "role", text: "役割" },
    { field: "status", text: "ステータス" },
]
const AddButton = ({ onClick }: { onClick?: () => void }) =>
    <button
        type="button"
        onClick={onClick}
        className="flex justify-center items-center bg-theme-50 hover:bg-theme-100 text-theme-800 w-12 h-12 rounded-3xl transition ease-in duration-200"
    >
        <PlusIcon className="w-8 h-8" />
    </button>

const usersData = gql`query GetUsers($query: UserQueryInput!) {
  users(query: $query, limit:200) {
    _id
    internal
    booking
    notice
    clinic {
        clinic_name
    }
    name
    role
    status
    }
}
`

const getQueryVariables = (names: KV): KV => {
    let query: KV = {}
    if (names.name) query.name = names.name
    if (names.clinic && names.clinic.clinic_name) query.clinic = { clinic_name: names.clinic.clinic_name }
    if (!names.internal && !names.external) {
        query.internal_exists = false
    } else if (names.internal && !names.external) {
        query.internal = true
    } else if (!names.internal && names.external) {
        query.internal = false
    } else {
        query.internal_exists = true
    }
    return query
}

export const List = () => {
    const navigate = useNavigate()
    const values: KV = useReactiveVar(filterStates)
    const names = values.user || { clinic: { clinic_name: "" }, name: "", internal: true, external: true }
    const { loading, data } = useQuery(usersData, { variables: { query: getQueryVariables(names) } })
    const setValue = (name: string, value: any) => {
        switch (name) {
            case "clinic.clinic_name":
                filterStates({ ...filterStates(), user: { ...names, clinic: { clinic_name: value } } })
                break
            default:
                filterStates({ ...filterStates(), user: { ...names, [name]: value } })
                break
        }

    }
    const handleClick = (e:MouseEvent<HTMLTableRowElement>) => {
        e.preventDefault()
        e.stopPropagation()
        navigate(`/user/edit/${e.currentTarget.dataset.id}`)
    }
    
    if (loading) return <div className="w-full h-full flex justify-center items-center"><Loading /></div>
    return <>
        <div className="absolute left-0 top-0 w-full h-full z-10 pointer-events-none">
            {<div className='fixed left-0 right-0 top-12 px-2 sm:px-4 bg-theme-100 pointer-events-auto'></div>}
            <Link
                className="fixed right-4 bottom-10 pointer-events-auto"
                to={`/user/create`}
            >
                <AddButton />
            </Link>
        </div>
        <div className="relative h-full grid grid-rows-list">
            <div className="bg-theme-200 p-2 relative">
                <div className="flex flex-wrap justify-center items-center gap-2">
                    <FilterCheck label="院内" name="internal" value={names.internal} setValue={setValue} />
                    <FilterCheck label="外部" name="external" value={names.external} setValue={setValue} />
                    <FilterInput label="施設名" name="clinic.clinic_name" value={names.clinic.clinic_name} setValue={setValue} />
                    <FilterInput label="氏名" name="name" value={names.name} setValue={setValue} />
                </div>
            </div>
            <div className="bg-white"></div>
            <div className="px-2 pb-2 h-full overflow-scroll flex flex-col z-0">
                <table className="mx-auto border-b border-gray-200 sm:rounded-b-md text-center text-sm text-gray-500">
                    <thead className="bg-theme-50">
                        <tr>
                            <th></th>
                            {heads.map((head, i) => (
                                <th
                                    key={(head.field || "") + i}
                                    data-name={head.field}
                                    data-value={i}
                                    scope="col"
                                    className="p-1 font-medium tracking-wider cursor-pointer"
                                >
                                    <div className="flex justify-center">
                                        <span>{head.text}</span>
                                    </div>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {data && [...data.users]
                            .map((datum) => (
                                <tr key={datum._id} data-id={datum._id} className={`text-sm text-gray-900 hover:bg-theme-50 cursor-pointer`} onClick={handleClick}>
                                    <td></td>
                                    <td>{datum.internal ? "◯" : ""}</td>
                                    <td>{datum.notice ? "◯" : ""}</td>
                                    <td>{datum.clinic?.clinic_name || ''}</td>
                                    <td>{datum.name || ''}</td>
                                    <td>{datum.role || ''}</td>
                                    <td>{options.userStatus[datum.status] || ''}</td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        </div>
    </>
}

export default List