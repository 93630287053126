import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

const useDocks = (book_id: string) => {
  const { data, loading, error } = useQuery(
      gql`
      query GetDock($bookID: Float!) {
        dock (query: {book_id: $bookID}) {
          _id
          book_id
          reception_date
          book_sub_id
          user_id
          patient_id
          birthdate
          patient_name_kana
          book_parent_id
          book_status
          course_name
          memo
          options {
            option_name
          }
          user_name
          update_status
          course_code
          patient_name
          ucc_book_token
          gender
          reception_time
          tel
          course_type_code
          course_type
          course_contents {
            modality
            modality_body1
            modality_body2
            modality_contrast
          }
        }
      }
    `,
      { variables: { bookID: parseFloat(book_id) } }
  )
  const dock = data?.dock ?? []
  return {
    loading,
    dock,
  };
};
export default useDocks;